import React from 'react'
import { Alert, Col, Row, Spinner } from 'react-bootstrap'
import { readEvalForm } from '../../../helper/SessionStorage'
import { reportGtmEvent } from '../../../helper/TagManagerHelper'
import { LangKey } from '../../../types/Languages'
import { IKit } from '../../../types/LicenseKit'
import Location from '../../../types/Location'
import { IEvalFormData, IProductRecord, IResponseEvalDownload } from '../../../types/Product'
import { ILicenseTableData, ILicenseTableRow } from '../../../types/Table'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import * as styles from './DownloadProduct.module.scss'
import LicenseKey from './LicenseKey'
import LicenseTable from './LicenseTable'
import ProductKitTable from './ProductKitTable'

class EvalDownloadProduct extends React.Component<DownloadProductProps, DownloadProductState> {
    constructor(props: DownloadProductProps) {
        super(props)

        this.state = {
            spinner: true,
            errorMsg: '',
            licenseKey: '',
            manualDescription: [],
            kits: [],
            directLink: false,
        }
    }

    componentDidMount = async () => {
        const evalFormData = readEvalForm()

        if (evalFormData) {
            const responseData = await this.fetchEvalDownloadData(evalFormData)

            if (responseData) {
                if (!(responseData.kits.length > 0)) {
                    this.displayError(this.props.t('template', 'eval.download.error.emptykits'))
                    return
                }

                const kits = responseData.kits

                let manualDescription: JSX.Element[] = []

                if (responseData.descriptions && responseData.descriptions.length > 0) {
                    manualDescription = await this.parseDescription(responseData.descriptions[0])
                }

                this.setState({
                    kits,
                    manualDescription,
                    spinner: false,
                })
            }
        } else {
            this.setState({
                directLink: true,
            })
        }
    }

    redirect = () => {
        let pathname = this.props.location.pathname
        pathname = pathname.replace('/download', '') // URL is being generated dangerously. Will be optimized with issue #526
        this.props.navigate(pathname)
    }

    parseDescription = async (descriptionRecord: IResponseEvalDownload['descriptions'][0]) => {
        const { parameters } = descriptionRecord
        const description: JSX.Element[] = []

        const descriptionWithPlaceholders = descriptionRecord.description

        let textBefore = ''
        let textRemaining: string = descriptionWithPlaceholders

        let i = 1 // for key prop only
        for (const param of parameters) {
            ;[textBefore, textRemaining] = textRemaining.split(param.placeholder)

            description.push(<span key={i++}>{textBefore}</span>)

            const handleClick = this.createHandleLinkClick(param.id, param.uri)

            description.push(
                <ContentButton key={i++} variant="link" size="sm" className="link-file" onClick={handleClick}>
                    {param.title}
                </ContentButton>,
            )
        }

        description.push(<span key={i}>{textRemaining}</span>)

        return description
    }

    createHandleLinkClick = (manualId: string, uri: string) => () => this.handleLinkClick(manualId, uri)

    handleLinkClick = async (manualId: string, uri: string) => {
        const linkUrl = await this.fetchManualUrl(manualId, uri)

        if (linkUrl) {
            Object.assign(document.createElement('a'), { target: '_blank', href: linkUrl }).click()
        }
    }

    fetchManualUrl = async (manualId: string, uri: string) => {
        const url = `/api/v1.0/Url/kitdownload/?kitId=${encodeURIComponent(manualId)}&kitUri=${encodeURIComponent(uri)}`

        try {
            const response = await axios.get(url)

            if (response && response.data) {
                return response.data.url
            }

            return null
        } catch (error: any) {
            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(this.props.t('form', 'general.form.error'))
        }
    }

    fetchEvalDownloadData = async (evalFormData: IEvalFormData) => {
        const { productCode, platformCode, evalType } = evalFormData

        const url = `/api/v1.0/Kits/EvalDownloadData/${productCode}/${platformCode}`

        try {
            reportGtmEvent('Eval', 'view', { type: evalType || '', product: this.props.product.code })
            const response = await axios.get(url)

            if (response.data) {
                return response.data as IResponseEvalDownload
            }

            return null
        } catch (error: any) {
            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            if (error.response && error.response.status === 410) {
                this.displayError(this.props.t('template', 'eval.license.conflict.existing'))
            } else {
                this.displayError(this.props.t('form', 'general.form.error'))
            }
        }
    }

    fetchLicense = async () => {
        if (this.state.licenseKey !== '') {
            return null
        }

        const evalFormData = readEvalForm()

        if (evalFormData) {
            const { productCode, platformCode, evalType } = evalFormData

            const url = `/api/v1.0/Kits/Evalkey/${productCode}/${platformCode}`

            try {
                reportGtmEvent('Eval', 'loadKey', { type: evalType || '', product: this.props.product.code })
                const response = await axios.get(url)

                if (response && response.data) {
                    this.setState({
                        licenseKey: response.data.key,
                    })
                }
            } catch (error: any) {
                if (error.response) {
                    console.log(`status code: ${error.response.status}`)
                    console.log(error.response.data)
                }

                this.displayError(this.props.t('form', 'general.form.error'))
            }
        }
    }

    fetchKitDownload = async (kit: IKit): Promise<void> => {
        const url = `/api/v1.0/Url/kitdownload/?kitId=${encodeURIComponent(kit.id)}&kitUri=${encodeURIComponent(kit.uri)}`
        const evalFormData = readEvalForm()
        if (evalFormData) {
            const { evalType } = evalFormData

            try {
                reportGtmEvent('Eval', 'download', { type: evalType || '', product: this.props.product.code })
                const response = await axios.get(url)

                if (response && response.data) {
                    const binaryDownloadUrl = response.data.url

                    if (binaryDownloadUrl) {
                        window.open(binaryDownloadUrl, '_self') // start download
                    }
                }
            } catch (error: any) {
                if (error.response) {
                    console.log(`status code: ${error.response.status}`)
                    console.log(error.response.data)
                }

                this.displayError(this.props.t('form', 'general.form.error'))
            }
        }
    }

    displayError(errorMsg: string) {
        this.setState({
            errorMsg,
            spinner: false,
        })
    }

    render() {
        const { t, product, language } = this.props

        const { errorMsg, spinner, licenseKey, manualDescription, directLink } = this.state

        const productKits = this.state.kits

        if (directLink) {
            this.redirect()
        }
        let content: any = (
            <div className={styles.spinnerContainer}>
                <Spinner animation="border" variant="primary" />
            </div>
        )

        const rows: ILicenseTableRow[] = [
            {
                data: [<td key={'td-1'}>{this.props.t('template', 'licence.table.row.licencekey')}</td>],
                keyRow: false,
                licenseId: null,
                clickHandler: this.fetchLicense,
                version: null,
            },
        ]

        if (licenseKey) {
            rows.push({
                data: [<LicenseKey key={'license-4000'} licenseKey={licenseKey} />],
                keyRow: true,
                licenseId: null,
                clickHandler: null,
                version: null,
            })
        }

        const heads = [
            <th key={'keys-sind-bloed'}>{this.props.t('template', 'licence.table.head.licence')}</th>,
            <th key={'rand'} />,
        ]

        const tableData: ILicenseTableData = {
            rows,
            heads,
        }

        const hasKits = this.state.kits.length > 0

        if (!spinner) {
            if (hasKits) {
                content = (
                    <>
                        <ProductKitTable productKits={productKits} language={language} onDownloadKit={this.fetchKitDownload} />

                        <LicenseTable tableData={tableData} />
                    </>
                )
            } else {
                content = null
            }
        }

        return (
            <>
                <Row>
                    <Col sm={12}>
                        <h1>{t('template', 'eval.download.title')}</h1>
                        <h2>{product.name}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <p>{manualDescription}</p>

                        {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}

                        {content}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withLocation(withTranslations(EvalDownloadProduct))

interface DownloadProductProps extends Translation, Location<any> {
    onSubmit?: any
    product: IProductRecord
    language: LangKey
}

interface DownloadProductState {
    kits: IKit[]
    manualDescription: JSX.Element[]
    spinner: boolean
    licenseKey: string
    errorMsg: string
    directLink: boolean
}
