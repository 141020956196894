import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContentButton from '../components/content/ContentButton'
import EvalDownloadProduct from '../components/content/download/EvalDownloadProduct'
import withTranslations from '../components/hoc/withTranslations'
import Banner from '../components/layout/Banner'
import * as styles from '../css/ProductEvalDownloadPage.module.scss'
import EvalBanner from '../images/eval_banner.png'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'

const ProductEvalDownloadPageTemplate: React.FunctionComponent<ProductEvalDownloadPageTemplateProps> = (
    props: ProductEvalDownloadPageTemplateProps,
) => {
    const { pageContext, t } = props
    const { templateData } = pageContext

    const { contactLink, quoteLink, product } = templateData

    return (
        <MainLayout pageContext={pageContext}>
            <Banner
                header={t('template', 'eval.banner.header')}
                headerText={t('template', 'eval.banner.headertext')}
                imageSrc={EvalBanner}
            />
            <Container>
                <Row>
                    <Col>
                        <p>{t('template', 'eval.intro.text')}</p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <EvalDownloadProduct product={product} language={pageContext.language} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <p>{t('template', 'eval.download.text.onedownload')}</p>
                        <p>{t('template', 'eval.download.text.zip')}</p>
                        <ul className={'ul-chevron'}>
                            <li>{t('template', 'eval.download.zip.product')}</li>
                            <li>{t('template', 'eval.download.zip.manual')}</li>
                            <li>{t('template', 'eval.download.zip.samplecode')}</li>
                        </ul>
                        <p>{t('template', 'eval.download.text.contact')}</p>
                    </Col>
                    <Col sm={4}>
                        <div className={styles.btnRight}>
                            <ContentButton to={quoteLink}>{t('template', 'general.button.quote')}</ContentButton>
                        </div>

                        <div className={styles.btnRight}>
                            <ContentButton to={contactLink}>{t('template', 'general.button.questions')}</ContentButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
}

export default withTranslations(ProductEvalDownloadPageTemplate)

interface ProductEvalDownloadPageTemplateProps extends Translation {
    pageContext: PageContext<any>
}
